.content
{ 
  padding-top: 10%;
}

.text_align_center{ text-align:center !important }
.text_align_left{ text-align:left !important }
.text_align_right{ text-align:right !important }

.backbutton
{
  border:1px solid #ddd;
  padding:10px;
  border-radius: 10px;
  color: #000 !important;
  font-weight: bold;
  background:#eee;
  text-decoration: none !important;
  cursor: pointer;
}

.backbutton:hover
{
  background-color:#ddd;
  
}
/*  
 ----------------  
 ----------------  
*/

.messages{ 
  padding: 1rem;
  background: #F7F7F7;
  flex-shrink: 2;
  overflow-y: auto;
  box-shadow: inset 0 2rem 2rem -2rem rgba(black, 0.05),
			inset 0 -2rem 2rem -2rem rgba(black, 0.05);
}
.messages .time{
  /* font-size: 0.8rem; */
  background: #EEE;
  padding: 0.25rem 1rem;
  border-radius: 2rem;
  color: #999;
  width: fit-content;
  margin: 0 auto;
}

.message {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  margin: 1rem 1rem 1rem auto;
  border-radius: 1.125rem 1.125rem 0 1.125rem;
  background: #fff;
  min-height: 2.25rem;
  width: fit-content;
  max-width: 66%;

  box-shadow: 0 0 2rem rgba(0,0,0, 0.075),
    0rem 1rem 1rem -1rem rgba(0,0,0, 0.1);

}
.message.from_her {
  margin: 1rem;
  border-radius: 1.125rem 1.125rem 1.125rem 0;
  background: rgb(176, 247, 252);
}

.message .typing {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0rem;
  box-sizing: border-box;
  background: #ccc;
  border-radius: 50%;
}

.message .typing-1 {
  animation: typing 3s infinite;
}
.message.typing-2 {
  animation: typing 3s 250ms infinite;
}
.message .typing-3 {
  animation: typing 3s 500ms infinite;
}
/* ---- */
.msg_block{
  width:100% !important;
}
.msg_block_ctn{
  border:1px solid #eee;
  background:#eee;
  padding:5px 10px;
  border-radius: 5px;
  margin-bottom: 3px;
  width:80%;
}
.msg_block_of_you .msg_block_ctn{  
  border:1px solid red;
}
.msg_block p{
  text-justify: justify;
  margin:0px;
}
.msg_block span{
  font-weight: bold;
  margin-bottom:50px;
}
.download_cursor{
  cursor: pointer;
}
.clickable, .clickable_cursor{
  cursor: pointer;
}
.msg_tools{
  width:100%;
  text-align:right;
  /* font-size:70%; */
  color:rgb(124, 124, 124);
}
/*  
 ----------------  
 ----------------  
*/
.div_presence_item > div{
  border:1px solid #eee;
  cursor:pointer;
  margin:5px;
  padding:5px;
  border-radius: 5px;
}
/*  
 ----------------  
 ----------------  
*/
.table_inf{
  width:100%;
}
.table_inf td, .table_inf th{ padding:5px;}
.table_inf td{
  border:1px solid #eee;
  border-radius:5px !important;
}

.div_btn{
  border-radius:5px;
  padding:5px 10px;
  margin:2px;
  background: #4DB2F0;
  text-shadow: 1px 1px #B8B8B8;
  font-weight:bold;
  display: block;
  color: #fff;
  letter-spacing: 1px;
  cursor:pointer;
  display:inline-block;
  -webkit-transition: background 1s; /* For Safari 3.0 to 6.0 */
  transition: background 1s; /* For modern browsers */
}


.wrapper{ /* for textarea area : Editor */
  border: 1px solid grey;
}

.div_btn:hover{
  background-color:#D4E6F1;
}

.modal-90w{
  min-width:90% !important;
}

.layout_without_center
{
  max-width:1000px;
  margin:auto auto;
}

.layout_without_center .page_title
{
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.justify, p{
  text-align: justify;
  text-justify: inter-word;
  line-height: 1.8;
}

.fc-daygrid-event{
  cursor:pointer;
}



.mb_variant
{
  padding:7px 10px;
  border-radius: 5px;
  font-weight:bold;
}

.mb_danger
{
  color:rgb(163, 41, 41);
  background-color:rgb(226, 149, 181);
}

.mb_warning
{
  color:rgb(189, 146, 27);
  background-color:rgb(238, 216, 168);
}

/*
--------------
--------------
*/

.padding_top_20 { padding-top:20px !important; }
.min_with_100{ min-width: 100px; }

.textAlignCenter{ text-align: center; }

.backWhite{ background-color: white; }

.aligned
{
  border-bottom:3px solid black;
  margin-bottom:20px;
}
.buttons_right_side
{
  float:right;
  display:inline-block;
  /* font-size: 12; */
}

.div_according{
  display:inline-block;
}

.question_item
{
  margin: 5px 10px;
  padding-top:10px;
  border:1px solid rgb(178, 178, 182);
  background-color: rgb(222, 247, 219);
  border-radius: 5px;
}

/*
------------
------------
*/
.signature_content{
  width:60%;
  margin:0px auto;
}
.signature_content div, .signature_content h1 { text-align: center;}
.signature_content .sigCanvas{
  margin:20px 0;
  border-width:5px;
}

@media only screen and (max-width : 600px) {
  .signature_content
  {
    width:100%;
  }
}



.dropdown-toggle::after {
  display: none !important;

}
.dropdown-menu 
{
  /* padding:0px !important; */
}
.dropdown-menu .dropdown-item
{
  padding:7px 10px !important;
}

.hidden_ele { display: none !important; }


.width_100{ width: 100% }
.vertical_align_top { vertical-align:top;}

[data-tooltip]:not([data-tooltip-persistent]):before {
  pointer-events: none;
}
[data-tooltip]:before {
  position : absolute;
  /* position : relative; */
  content : attr(data-tooltip);
  opacity : 0;
  border-radius: 5px;
  padding: 5px 5px;
  background:white;
  color:black;
  box-shadow:0px 0px 5px rgb(205, 175, 114);
  top:-15px;
  
  /* right:0px; */
  /* left:calc(-1 * attr(data-left)); */
  /* bottom:30px;
  right:10px; */
  z-index: 1;
}
.data-tooltip-qcm[data-tooltip]:before {
  top:5px;
  right:60px;
}

[data-tooltip]:hover:before {        
  opacity : 1;
}
.width_as_content [data-tooltip]:before {
  top: 40px;
  right:0px;
}
.button_stand_relative [data-tooltip]:before {
  top:-30px;
}

.button_stand_relative.backleft > [data-tooltip]:before {
  left:-100px;
}

.width_as_content { display: inline-block;}
.fc-toolbar-title { text-transform: capitalize; }

.login_div { text-align: center; }
.login_div img
{
  max-width:400px;
  margin-bottom: 10px;
  width:50%;
}

.login_div_form
{
  max-width:500px;
  padding:10px 50px;
  margin:auto;
}
.login_div_form .form-group { 
  text-align: left;
  font-weight:bold
}

.loginOR
{
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid rgb(211, 212, 214); 
  line-height: 0.1em;
  margin: 20px 0 15px; 
}
.loginOR span {
  background:#f3f3f3; 
  padding:0 10px; 
}

.section_title_two > div { margin:0px !important; }
.section_title_two > .title_section 
{ 
  font-size:140%;  
  letter-spacing:1px;
}
.dropdown-toggle.btn.btn-primary
{
  background-color: white !important;
}

.sigPadContainer {
  background-color: rgb(238, 235, 235);
  padding: 0 10px 10px;
}
.sigCanvas{
  width: 100%;
  height: 200px;
  border:1px solid #eee;
}

.train_info_ses_page
{
  display: inline-block;
  margin:2px;
}

.nl2br
{
  white-space: pre-line;
}

.survey_global_score
{
  font-weight: bold;
  font-size: 200px;
  width: 400px;
  text-align: center;
  padding: 0px;
  display: inline-block;
  border-radius: 30px;
  border:5px solid white;
  box-shadow: 0px 0px 100px rgb(248, 250, 255) inset;
}

.survey_global_score .sur_10
{
  font-weight: bold;
  display:inline-block;
  font-size: 80px;
}

.survey_item_score
{
  font-weight: bold;
  font-size: 40px;
  width: 110px;
  text-align: center;
  padding: 10px 0px 6px;
  display: inline-block;
  border-radius: 15px;
  border:1px solid white;
  box-shadow: 0px 0px 100px rgb(248, 250, 255) inset;
}

.survey_item_score .sur_10
{
  font-weight: bold;
  display:inline-block;
  font-size: 20px;
}

.popup_reponse .survey_item_score { font-size: 24px; width: 80px; }
.popup_reponse .survey_item_score .sur_10 { font-size: 18px; }

.survey_global_score.good_point, .survey_item_score.good_point  { color:rgb(27, 197, 47); }
.survey_global_score.medium_point, .survey_item_score.medium_point  { color:rgb(218, 100, 4); }
.survey_global_score.bad_point, .survey_item_score.bad_point  { color:rgb(249, 49, 35); }

.survey_fich_qst_annonce { border-radius: 5px 5px 0px 0px; display: block; padding:5px 10px; border:2px solid purple; background:purple; color:#FFF; font-weight: bold; font-size:120%; }
.survey_fich_qst_reponse { border-radius: 0px 0px 5px 5px; display: block; padding:5px 10px; border:2px solid purple; margin-bottom: 5px; }
.display_flex
{
  display: flex;
}

.margin_all_10px { margin:10px; }
.margin_left_10px { margin-left:10px; }

.button_disabled{
  background-color:rgb(195, 195, 195) !important;
}
.button_disabled svg { color:black !important; }

.question_item_grp_name
{
  font-weight:bold;
  border:1px solid #eee;
  padding:10px 20px;
  border-radius: 10px;
  display: inline-block;
  margin-bottom: 20px;
}

.table_tag
{
  border-radius:4px;
  padding:5px 10px;
}

.table_qcm_reponse {
  width: 100%;
  border-collapse: collapse;
}

.table_qcm_reponse td, .table_qcm_reponse th {
  border: 1px solid #ddd;
  padding: 2px 8px;
}

.table_qcm_reponse tbody tr:first-child td
{
  background-color: rgb(241, 244, 246);
  font-size:150%;
}

.table_qcm_reponse tbody tr:first-child td
{
  background-color: rgb(241, 244, 246);
  font-size:150%;
}

.table_qcm_reponse tbody tr.is_true:first-child td 
{ background-color: #D0ECE7; }

.table_qcm_reponse tbody tr.is_false:first-child td 
{ background-color: #FADBD8; }

.table_qcm_reponse tbody tr:not(:first-child) td:first-child{
  padding-left: 30px;
}

.table_qcm_reponse th:not(:first-child),
.table_qcm_reponse td:not(:first-child){
  text-align:center;
  position: relative;
  padding:0px !important;
}
.table_qcm_reponse .table_tag {
  font-weight: bold;
  padding:0px 10px;
  border-radius: 10px;
  margin-left: 30px;
  font-size: 60%;
}
.table_qcm_reponse .table_tag{
  background-color: white
}

.table_qcm_reponse .dot {
  height: 18px;
  width: 18px;
  position: absolute;
  top: 20%;
  background-color: rgb(55, 55, 55);
  border-radius: 50%;
  display: inline-block;
}

.dot.is_true
{ background-color: #D0ECE7; }

.dot.is_false
{ background-color: #FADBD8; }

.table_enq_reponse {
  width: 100%;
  border-collapse: collapse;
}

.table_enq_reponse td, .table_enq_reponse th {
  border: 1px solid #ddd;
  padding: 2px 8px;
}

.table_enq_reponse tbody tr:not(:first-child) td:first-child
{
  width:100px;
  text-align: center;
  font-weight: bold;
  text-align: right;
}

.table_enq_reponse tbody tr:nth-child(even):not(:first-child) td
{
  background-color: rgb(245, 245, 245);
  
}


.table_enq_reponse tbody tr:first-child td
{
  background-color: rgb(241, 244, 246);
  font-size:150%;
}

.table_enq_reponse tbody tr td:last-child
{
  text-align: right;
  padding-right: 25px;
}

.table_enq_reponse .reponse_line td {
  padding-bottom: 6px;
}

fieldset
{
  margin-top: 20px;
  padding:16px;	
  padding-bottom: 5px;
  border:1px solid #e3e3e3;
  box-shadow: 0 0 10px #eee;
  border-radius: 5px;
  margin-bottom: 10px;
}
fieldset > caption
{
  white-space: nowrap;
  margin-top:-31px;
  background-color:#FFF;
  padding: 5px;
  color:#000;
  font-size: 80%;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
}