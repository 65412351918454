.profil_widget_avatar_Comp
{
    
}

.profil_widget
{
    display: inline-block;
    background-color: white;
    
    .with_border
    {
        border:1px solid #eee;
        box-shadow: 0 0 5px #eee;
    }
}

.transparent{
    background-color: transparent !important;
}

.vertical_show
{
    display: table;
    width: 100%;
    border-radius: 5px;
    margin:0px;

    .profil_widget_avatar
    {
        display: table-cell;
        padding: 0px !important;
    }

    .profil_widget_avatar
    {
        display: table-cell;
        padding: 5px;
        padding-top: 10px;
    }
    
    .profil_widget_info
    {
        display: table-cell;
        padding: 0px 10px !important;
        vertical-align: middle;

        .btn
        {
            margin-right:5px;
            size: 10px;
            box-shadow: 0 0 5px #eee;
            border:1px solid #eee;
            color:#000;
            position: relative;
        }
    }
}

.horizontal_show
{
    margin:10px;
    border:1px solid #eee;
    box-shadow: 0 0 5px #eee;
    border-radius: 5px;
    margin:10px;
    
    .profil_widget_avatar
    {
        padding:5px 0;   
        text-align: center;
        margin: 0 auto;
    }
    
    .profil_widget_info
    {
        padding: 5px !important;
        padding-right: 20px !important;
        text-align: center;

        .btn
        {
            margin:10px;
            size: 10px;
            box-shadow: 0 0 5px #eee;
            border:1px solid #eee;
            color:#000;
        }
    }
}

.profil_widget_name
{
    font-weight: normal;

    div{
        display: inline-block;
        white-space: pre;
    }
}

.profil_widget_title
{
    font-weight: normal;
    text-align: center;
    font-size: 100%;
}

.profil_widget_info_sub_title
{
    color:rgb(105, 102, 102);
    font-style: italic;
}