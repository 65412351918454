// $color_1: #fb8500;
$color_1: #0DB5ED;
$color_2: #5DA2D5;
// $color_2: #23d1b4;
$color_3: #F3D250;
$color_4: #fb000d;
$color_5: #90CCF4;
$color_6: #5DA2D5;
$color_7: #F78888;
$color_8: #cccccc;
$color_prf_form: #DAF7A6;
$color_prf_cmtp: #52C6CF;
$color_prf_secr: #FFC300;
$color_prf_tran: #7ACF52;

body
{
    /* border : 10px solid red; */
}

.there_even abbr::after
{
    content:'\2022';
    color: $color_4;
    font-size:32px;
}

.react-calendar
{
    border:0px;
}

.react-calendar__navigation__arrow
{
    border-radius:50%;
    margin:5px;
}

.is_block_calendar
{
  border:3px solid $color_2;
  box-shadow: 0 0 2px $color_2;
  border-radius: 10px;
  padding:10px;
  background-color: white !important;
  margin-bottom: 10px;
}
.react-calendar__tile
{
    border:1px solid #eee;
    border-radius:10px;
    margin:2px 2px !important;
    flex: 0 0 13% !important;
}
.react-calendar__tile--now, .react-calendar__tile--now:hover
{
    background:#1087ff !important;
    color:white;
}
.react-calendar__tile--active
{
    background:#FFC300 !important;
    color:black;
}
.react-calendar__month-view__days__day--weekend 
{
    color:black;
    background-color: #eee;
}