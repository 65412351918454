// $color_1: #fb8500;
$color_1: #0DB5ED;
$color_2: #5DA2D5;
// $color_2: #23d1b4;
$color_3: #F3D250;
$color_4: #fb000d;
$color_5: #90CCF4;
$color_6: #5DA2D5;
$color_7: #F78888;
$color_8: #cccccc;
$color_prf_form: #DAF7A6;
$color_prf_cmtp: #52C6CF;
$color_prf_secr: #FFC300;
$color_prf_tran: #7ACF52;
$color_qcm_session: #eee;
$color_qcm_pre: red;
$color_qcm_post: blue; 

$color_inp: #07b5f5;
//$color_4: #90CCF4;
//$color_4: rgb(205, 175, 114);
$color_4_opc: rgba(205, 175, 114, 0.2);
//$color_5: #8ecae6;
//$color_6: #7D8767;



$color_primary: #009ee9;
$color_danger: red;
$color_warning: rgb(255, 174, 0);
$color_background: #fff;
$color_content_background:#fff;
$color_shadow: rgb(167, 167, 167);;
$color_shadow_hover: rgb(190, 190, 190);
$color_navbar_text: black;

html, body {
  height: 100%;
  margin: 0;
}
body{
  height: 100%;
  font-family: "Times New Roman", Times, serif !important; 
}
#root
{
  background-color: $color_background !important;
  height: 100%;
}
.HomePageDiv
{
  height: 100% !important;
}
.HomeMemberPageDiv
{
  // border:1px solid yellow;
  // display: inline-block;
  // height: 100% !important;
}
.HomeMemberContentPageDiv
{
  
}
.HomeCenterPageDiv
{
    // display: flex;
    // flex-direction: row;
    // flex-wrap: nowrap;
    
    height: 100%;   
    overflow: hidden;  
    margin: 0px;  /* removes default style */
    // display: flex;  /* enables flex content for its children */
    // box-sizing: border-box;
    // justify-content: center;
    // align-items: flex-start;
}
// .SideBarDiv
// {
//   width:180px;
//   min-width: 180px !important;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   flex-shrink: 0;
//   border:0.02em solid #eee;
//   border-left: 0;
//   border-top: 0;
//   // border-left: 6px solid $color_2;
// }
// .ContentPageDiv
// {
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   flex-grow: 1;
//   order: 2;
//   padding:10px 10px;
// }

// .tableOfPage
// {
//   width: 100%;
// }

.SideBarDiv
{
  height: calc(100vh - 80px);
  width: 180px;
  position: absolute;
}
.ContentPageDiv
{
  min-height: calc(100vh - 80px);
  margin-left: 180px;
  padding:10px 10px;
}
.tableOfPage
{

}

@media only screen and (max-width: 600px) {
  .SideBarDiv {
    position: relative;
    height: auto;
  }
  .nabar_sub_style.flex_between
  {
    display: block;
  }
  .SideBarDiv, .ContentPageDiv {
    width: 100%;
    padding:0px;
    margin:0;
    margin-bottom:10px;
  }
  .nabar_sub_style .nav_bar_button span { display:none;}
}

@media only screen and (min-width : 500px) {
  .SideBarDiv
  {
    // width:250px;
  }
//   .ContentPageDiv
//   {
//     // width: -webkit-calc(100% - 250px);
//     // width:    -moz-calc(100% - 250px);
//     // width:         calc(100% - 250px);
//   }
}

.is_block
{
  border:1px solid $color_2;
  box-shadow: 0 0 2px $color_2;
  border-radius: 5px;
  padding:10px;
  background-color: white !important;
  margin-bottom: 10px;
}

.section_title_two
{
  display: flex;
  justify-content: space-between;
  margin:-10px;
  border-radius:2px 2px 0 0;
  margin-bottom: 10px;
  padding:10px;
  padding-bottom: 0px;

  .title_section{
    border-bottom:5px solid rgb(1, 0, 75);
    padding-right: 15px;
  }
}

.flex_between
{
  display: flex;
  justify-content: space-between;
}

.block_link
{
  border-left:6px solid $color_2;
  margin-bottom: 8px;
  padding-left: 2px;
  white-space: nowrap;
}

.divider {  }
.divider_right {
  width: 100%;
  border-right: 1px double $color_8;
}

.apropos_logo
{
  padding:10px;
  margin-bottom: 20px;
  img { max-width: 200px; max-height: 100px;}
}

code {
}

.button_stand_tbl
{
  margin:5px 3px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 0 4px $color_2 !important;
  // box-shadow: 0 0 4px $color_shadow;
  background-color:white;
  display:inline-block;
  border: 0px;
  height: auto;
  cursor: pointer; 
}
.button_stand_tbl td{
  border:1px solid #eee;
  padding:5px 10px;
}
// UPDATE BOOTSTRAP COLOR
.button_stand
{
  padding:5px 10px;
  margin:5px 3px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 0 4px $color_2 !important;
  // box-shadow: 0 0 4px $color_shadow;
  background-color:white;
  display:inline-block;
  border: 0px;
  height: auto;
  cursor: pointer; 
}
.button_stand button{
  background-color:white;
  padding:5px 10px;
  margin:0px;
}

.button_stand_relative
{
  position: relative;
}


.button_stand:hover
{
  box-shadow: 0 0 4px $color_shadow_hover;
}

.button_stand svg
{
  color:$color_4;
}

.button_stand.button_bg
{
  background-color: rgb(26, 147, 247) !important;
  color: white;
}

.buttons_table
{
  
}




.without_margin, .margin_0
{
  margin:0px !important;
}
.without_padding, .padding_0
{
  padding:0px !important;
}

.pageTitle
{
  position: relative;
  margin-top:10px;
  margin-bottom: 40px;
  text-align: center;
}
.pageSubTitle
{
  margin-top: 10px;
}

.input-group
{
  select, .button_stand
  {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .button_stand
  {
    border:1px solid $color_2;
    margin: 0px;
    box-shadow: 0;
  }
  input 
  {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
}

.adapt_to_h { 
  display: inline-block; position: absolute; right: 10px; top:7px;

  .info_to_h { 
    
    // font-size: 16px !important;
    margin:20px;
  }
}



.btn-primary
{
  background-color: $color_2 !important;
  border:0px !important;
}
.button_danger
{
  background-color: $color_danger !important;
  color:white;
  svg { color:white !important; }
}
.button_white
{
  background-color: white !important;
  color:black !important;
}
.button_download{
  background-color: $color_2;
  svg { color:white !important; }
}
.button_green
{
  background-color: rgb(163, 245, 169) !important;
  color:black !important;
}

.text_info { color:rgb(72, 175, 79); font-weight: normal !important }
.text_danger { color: $color_danger; font-weight: normal !important; }
// NAVBAR 
.nav_bar_button
{
  display: inline-block;
  padding:10px 10px;
  margin:0px;
  
  
  color:black;
  background-color: white;
  font-weight:bold;
  cursor:pointer;
  text-transform: uppercase;
  font-weight: normal;
  border-bottom: 1px solid transparent;
}

.nav_bar_button:hover, .nav_bar_button:visited, .nav_bar_button:link, .nav_bar_button:active
{
  text-decoration: none;
}


.nav_bar_button:hover
{
  background-color: $color_2;
  border-bottom: 1px solid white;
}

.nav_bar_button_selected
{
  color:white;
  background-color: $color_2;
}
// SIDEBAR

.side_bar_button
{
  display: inline-block;
  box-shadow: 0px 0px 1px $color_shadow;
  padding:10px 10px;
  // margin:10px;
  // margin-right:0px;
  
  color:$color_navbar_text;
  background-color: white;
  font-weight:bold;
  cursor:pointer;
  text-transform: uppercase;
  font-weight: normal;
  
}

.side_bar_button:hover, .side_bar_button:visited, .side_bar_button:link, .side_bar_button:active
{
  text-decoration: none;
}


.side_bar_button:hover
{
  background-color: $color_2;
  // opacity:0.8;
  color:white;
}

.side_bar_button_selected
{
  color:white;
  background-color: $color_2;
}


.nabarstyle
{
  text-align:right;
  padding:10px 13px;
  background-color: $color_2;
  box-shadow: 0 0 7px $color_2;
  position: relative;
  
  .logo
  {
    img{
      max-width: 100px;
      max-height: 70px;
      margin: 0 40px !important;
    }
  }

  .nav_bar_button
  {
    background-color: transparent;
    
    a{
      display: block;
      color:white;
      background-color: transparent;
      font-weight:bold;
      cursor:pointer;
      text-transform: uppercase;
      font-weight: normal;
      text-decoration: none;
    }
  }
  .nav_bar_button:hover
  {
    background-color: $color_8;
    a { background-color: $color_8; }
  }
}

.nabar_sub_style
{
  text-align:center;
  box-shadow: 0 0 7px $color_8;
  position: relative;
  
  .logo
  {
    padding: 10px;
    // padding-left: 35px;

    img{
      max-width: 200px;
      max-height: 60px;
    }
  }

  .nabar_buttons
  {
    align-self: flex-end;
  }

  .nav_bar_button
  {
    background-color: transparent;
    border-bottom: 0px;

    a{
      display: block;
      color:black;
      background-color: transparent;
      font-weight:bold;
      cursor:pointer;
      text-transform: uppercase;
      font-weight: normal;
      text-decoration: none !important;
      
    }

    svg { margin-right: 5px;}
  }
  .nav_bar_button:hover
  {
   background-color: $color_5; 
   border-color: $color_5; 
  }
}



.is_table
{
  display: table;
  width: 100%;
}

.is_table_row
{
  display: table-row;
}

.is_table_col
{
  display: table-cell;
  vertical-align: top;
}

.rdt_TableCol, .rdt_TableCell {
  // font-size:15px;
}

.image_show_circle_size
{
  width: 80px;
  border-radius: 50%;
  box-shadow: 0 0 10px $color_shadow;
}

.image_show_small_size
{
  width: 120px;
  border-radius: 10px;
  box-shadow: 0 0 10px $color_shadow;;
}

.image_show_full_size
{
  width: 200px;
  box-shadow: 0 0 10px $color_shadow;;
}

.margin_5 { margin : 5px; }
.margin_10 { margin : 10px; }
.margin_15 { margin : 15px; }
.margin_20 { margin : 20px; }

.session_item_list
{
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px 20px;
  
  .session_item_list_title
  {
    font-weight: bold;
  }

  .state
  {
    display: inline-block;
    padding: 1px 8px;
    border:0px;
    border-radius: 5px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
  }
}
.rdt_TableBody{
  .rdt_TableRow:nth-child(even){background-color: #fbfafa;}
}

.rdt_TableHeadRow
{
  font-size: 130%;
  // font-weight: bold;
  background-color: $color_2 !important;
}
.rdt_TableRow:hover{
  background-color: $color_4_opc !important;
}

.session_item_a_venir
{ 
  border:1px solid rgba(63, 195, 128, 0.3) !important;
  box-shadow: 0 0 2px rgba(63, 195, 128, 0.3);
  
  .state { background-color: $color_2;}
}
.session_item_passer
{ 
  border:1px solid rgba(248, 148, 6, 0.3) !important;
  box-shadow: 0 0 2px rgba(248, 148, 6, 0.3);
  
  .state { background-color: $color_4;}
}
.session_item_en_cour
{ 
  border:1px solid rgba(242, 38, 19, 0.3) !important;
  box-shadow: 0 0 2px rgba(242, 38, 19, 0.3);
  background: linear-gradient(0.25turn, transparent, $color_3) !important;
  .state { background-color: $color_3;}
}

.session_mini_item
{
  border:0px;
  border-radius:5px;
  padding:5px;
}

.session_mini_item_a_venir
{ 
  background-color: rgba(63, 195, 128, 0.3) !important;
}

.session_mini_item_passer
{ 

  background-color: rgba(248, 148, 6, 0.3) !important;
}

.session_mini_item_en_cour
{ 
  background-color: rgba(242, 38, 19, 0.3) !important;
}


.session_item_list:hover{
  background-color: rgb(247, 247, 247);
}

.search
{
  border:0px;
  border-bottom: 3px solid #eee !important;
  margin-top: 15px;
  
  .icon
  {
    background-color: transparent;
    border:0px;
    padding:0 2px;
  }
  .text_zone
  {
    border: 0;
    padding: 0 5px;
    margin: 0 !important;
  }
  .text_zone:focus
  {
    border:0;
    box-shadow:0;
    border:0 !important;
    box-shadow: none !important;
  }
}
.search.width_50
{
  width: 50% !important;
}
@media only screen and (min-width : 400px) {
  .search.width_50
  {
    width: auto !important;
  }
}
.session_title
{
  text-align: center;
  
}
.session_group
{
  .session_group_title
  {
    // font-size: 30px;
    background: linear-gradient(0.25turn, $color_2, $color_content_background) !important;
    color:white;
    padding:0 0px 0 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .session_group_content
  {
    margin-bottom: 30px;
  }
}
.float_right { float: right; }
.float_left { float: left; }
.align_right { text-align: right; }
.align_left { text-align: left; }
.display_content
{
  display: inline-block;
}
/*  
 ----------------  
 ----------------  
*/
.note_block{
  border:1px solid #eee;
  background:#eee;
  padding:5px 10px;
  border-radius: 5px;
  margin-bottom: 3px;
}
.note_block p{
  text-justify: justify;
  margin:0px;
}
.note_block span{
  font-weight: bold;
  margin-bottom:50px;
}

.div_file{
  padding:5px 10px;
  border-radius: 5px;
  margin:1px;
  margin-bottom: 3px;
  display: inline-block;
  cursor: pointer;
  background-color:rgba(63, 195, 128, 0.3);
}

.note_block .div_file{
  border:1px solid #eee;
  background:$color_1;
  padding:5px 10px;
  border-radius: 5px;
  margin-bottom: 3px;
  display: inline-block;
  cursor: pointer;
}
.line_horaire { margin: 0 !important; padding:0 !important;}
.line_horaire div
{
  border: 1px solid $color_2;
  margin:1px;
  border-radius: 5px;
}
.line_horaire:hover div{
  background-color: $color_2;
}


/*
-------------------
-------------------
*/


.table_ini {
  border-collapse: collapse;
  width: 100%;
}

.table_ini td, .table_ini th {
  border: 1px solid #ddd;
  padding: 2px 8px;
}

.table_ini tr:nth-child(even){background-color: #ddf8ff;}

.table_ini tr:hover {background-color: #ddd;}

.table_ini th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: $color_2;
  color: white;
}

/*
-------------------
-------------------
*/
.table_horaire {
  border-collapse: collapse;
  width: 100%;
  .horaire_cell {
    
    div{
      display: inline-block;
      background-color: white;
      margin:0 2px;
      padding:0 4px;
      border-radius: 2px;
      border:1px solid #eee;
      box-shadow: 0 0 5px #eee;
  
    }
  }
}

.table_horaire td, .table_horaire th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table_horaire tr:nth-child(even){background-color: $color_4_opc;}

.table_horaire tr:hover {background-color: #ddd;}

.table_horaire thead th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: $color_2;
  color: white;
}

.table_horaire .active{
  background-color: white;
  text-align: center;
}

/*
-------------------
-------------------
*/

.ql-container
{
  border-radius: 0 0 5px 5px; 
}

.is-invalid .ql-container
{
  border:2px solid red !important;
}

.is-valid .ql-container
{
  border:2px solid rgb(109, 160, 103) !important;
}

//
.info_bloc_items{
  display: flex;
  flex-wrap: wrap;
  
  .infob_item 
  {
    border:1px solid $color_8;
    box-shadow: 0 0 3px $color_8;
    border-radius: 5px;
    background-color: #ffffff !important;
    
    width:31%;
    height:160px;
    position: relative;
    margin:10px;

      
    overflow-x: hidden;
    
    .infob_title
    {
      padding:2px 0px 2px 10px ;
      font-weight: bold;
      color: $color_6;
      font-size: 150%;

    }

    .infob_nbr
    {
      display: inline-block;
      font-size: 15px;
      // background: $color_8;
      box-shadow: 0 0 5px $color_2;
      color:black;
      font-weight: normal;
      width: 20px;
      height: 20px;
      text-align: center;
      border-radius: 50%;
      margin:10px;
      
    }

    .infob_content
    {
      padding:2px 10px;
      color: black;
    }

    .infob_link
    {
      border-left:6px solid black;
      margin-bottom: 8px;
      padding-left: 2px;
      white-space: nowrap;
    }

    .infob_link:hover{
      font-weight: bold;
    }

    .infob_footer
    {
      font-weight: bold;
    
      color:black;
      background: linear-gradient(transparent 0%, $color_8 100%);

      
      text-align: center;
      border-radius: 0 0 6px 6px ;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  .satisfaction
  {
    height: auto !important;
  }
}

@media only screen and (min-width : 1000px) {
  .info_bloc_items
  {
    .infob_item 
    {
      width:23%;
    }
  }
}
@media only screen and (max-width : 1270px) {
  .info_bloc_items
  {
    .infob_item 
    {
      width:30%;
    }
  }
}
@media only screen and (max-width : 630px) {
  .info_bloc_items
  {
    .infob_item 
    {
      width:45%;
    }
  }
}
@media only screen and (max-width : 480px) {
  .info_bloc_items
  {
    .infob_item 
    {
      width:100%;
    }
  }
}

.select_center_contener
{
  text-align: center;

}
.profil_show_item_value
{
  margin-bottom: 10px;
  min-height: 35px;
}

.select_center_item
{
  display:inline-block;
  padding:5px 7px;
  cursor:pointer;
  margin:10px;

  width: 40%;
  text-align: center;

  .center_name{
    padding:2px 0px 2px 10px ;
    font-weight: bold;
    color: $color_4;
    // font-size:140%;
  }
  .center_image
  {
    height: 100px;
    display:flex;
    align-items:center;
    
    img
    {
      margin:0 auto;
      max-width: 100%;  
      max-height: 70px;
      text-align: center;
    }
  }
  
}
.select_center_item:hover
{
  box-shadow: 0 0 5px $color_2;
}

.badge
{
  border-radius: 50%;
  background-color: $color_1;
  color: white;
  float: right;
}

.Home_info_bloc
{
  padding: 0 30%;
  
  .Home_info_logo
  {
    padding: 20px;
    padding:5px 7px;
    
    img
    {
      max-width:100%;
    }
  }

  .logoCenter_home {
    max-width: 400px;
    max-height: 200px;
  }
}


.pagination_content
{
  text-align: center;

  .current
  {
    background-color:$color_2;
  }
}

.centerForm
{
  max-width: 500px;
  margin:auto;
  text-align: center;

  input{
    text-align: center;
  }
}


.profil_show_item
{
  
}

.profil_show_item_title
{
  display:inline-block;
  margin-right: 5px;
}
.profil_show_title
{
  display:inline-block;
  margin-right: 5px;
  width:100%;
  border-bottom:1px solid rgb(206, 206, 206);
  margin-bottom:20px;
  padding-bottom:10px;

  background: #ECECEC;
}

.centre_info_block
{
  .col{
    margin-bottom:3px;
    margin-right: 5px;
    background: white;
  }
  .profil_show_item_title{
    font-weight: bold;
  }
  .profil_show_item_title, .profil_show_item_value{
    display: inline-block;
  }
}

.show_info_as_form
{
  .profil_show_item_title{
    font-weight: bold;
  }
  .profil_show_item_value{
    background-color: white;
    padding:5px;
    border-radius: 5px;
    border:1px solid #ECECEC;
  }
}

.wherebydiv
{
  border:1px solid #eee;
  overflow:auto;
}
.wherebyframe
{
  position: relative;
  background-color:#eee;
  margin-top: -34px !important;
}



[data-icon=external-link-alt]{
  color:$color_2;
}
[data-icon=plus]{
  color:rgb(40, 130, 48) !important;
}
[data-icon=check]{
  color:green !important;
}
[data-icon=cogs]{
  color:rgb(190, 128, 3) !important;
}
[data-icon=user-lock]{
  color:rgb(13, 144, 184) !important;
}
[data-icon=times]{
  color:red !important;
}
[data-icon=cog]{
  color:rgb(222, 171, 20) !important;
}
[data-icon=file-download]{
  color: $color_6 !important;
}
[data-icon=info-circle]{
  color:$color_1 !important;
}
[data-icon=file]{
  color:rgb(62, 158, 134) !important;
}

[data-icon=certificate]{
  color:#42b04d !important;
}
[data-icon=stamp]{
  color:#a97643 !important;
}
[data-icon=envelope]{
  color:#F3D250 !important;
}
[data-icon=info]{
  color:rgb(28, 148, 246) !important;
}
[data-icon=arrow-left]{
  color:blue !important;
}
[data-icon=eye]{
  color:blue !important;
}
[data-icon=eraser]{
  color:rgb(254, 58, 113) !important;
}
[data-icon=arrow-right]{
  color:blue !important;
}
[data-icon=save]{
  color:green !important;
}
[data-icon=file-signature]{
  color:#b4b4b4 !important;
}
[data-icon=download]{
  color:black !important;
}
[data-icon=file-alt]{
  color:rgb(195, 212, 36) !important;
}
[data-icon=check-double]{
  color:red !important;
}
[data-icon=pencil-alt]{
  color:rgb(16, 102, 173) !important;
}
.icon_purple
{
  color:rgb(255, 1, 132) !important;
}
.icon_green
{
  color:rgb(13, 102, 29) !important;
}

select:focus, input:focus , textarea:focus
{
  border-color: $color_inp !important;
  box-shadow: 0px 0px 2px $color_inp !important;
}

option:hover{
  background-color: $color_inp !important;
}

input:checked + label {
  text-decoration: underline;
}

input[type="radio"]:checked {
  box-shadow: 0px 0px 2px $color_4 !important;
}

input[type="checkbox"]:checked {
  box-shadow: 0px 0px 2px $color_4 !important;
}


option:checked {
  box-shadow: 0px 0px 2px $color_4 !important;
}

.fc-daygrid-day-number
{ 
  background-color: #ECECEC !important; 
  color:black;
  border-radius: 5px; 
  box-shadow: 0 0 5px grey;
  margin-top:5px;
  margin-right: 5px;
  text-decoration: none !important;
  margin:5px auto ;
  width: 50px;
  text-align: center;
}

.fc-day-today
{
  .fc-daygrid-day-number
  {
    background-color: $color_1 !important;
  }
}
.fc-col-header
{
  .fc-col-header-cell
  { 
    
    border:1px solid $color_2; 
  
    .fc-scrollgrid-sync-inner { background-color: $color_2;  }
    .fc-col-header-cell-cushion { color: white;
      font-weight: normal;
    }
  }
  
}


.headerContentPart
{
  margin-bottom: 40px;

  .title{
    font-weight: bold;
    font-size: 200%;

  }
  .sub_title{
    font-weight: normal !important;
    color: rgb(88, 88, 88);
    font-size: 80% !important;
    margin-left: 10px;
    display: inline-block;
  }
  .button_stand
  {
    margin: 0px;
    padding:6.5px;

    margin-left: 8px;

    button{ padding:6.5px;}
  }
  .col
  {
    margin: 0px;
  }
}

.withoutstyle{
  *{ 
    margin-top:0px;
    margin-bottom:0px;
    padding-top:0px;
    padding-bottom:0px;
  }
}

.ql-editor{ background-color: white;}

.presence_com
{
  
  .presence_com_title
  {
    color:$color_4;
    font-size: 80%;
    
    svg {
      float:right;
      cursor: pointer;
    }
  }
  .presence_com_content
  {

  }
}

.presence_com:not(:last-child)
{
  border-bottom:1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 10px;
}


input, select, textarea 
{
  margin-bottom: 10px !important;
}
input, .profil_show_item_value, select, .is_block, .infob_item, textarea
{
  border-color:$color_2 !important;
}
.form-label{
  margin: 0 !important;
}

.select_component > div{
  border-color:$color_2 !important;
}
.select_component
{
  
  
  input, input:focus, input::after{
    margin: 0px !important;
    padding: 0px !important;
    border:0px !important;
    box-shadow: none !important;
  }
}
.select_component:focus
{
  div {
    border-color:$color_2 !important;
  }
}





.rdt_Pagination select { margin:0px !important;  } // footer of tables



.bg_prf_form { background-color: $color_prf_form; }
.bg_prf_cmtp { background-color: $color_prf_cmtp; }
.bg_prf_secr { background-color: $color_prf_secr; }
.bg_prf_tran { background-color: $color_prf_tran; }

.div_qcm_session { 
  display: inline-block;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: bold;
  background-color: $color_qcm_session;  
}

.div_qcm_pre { 
  display: inline-block;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: bold;
  background-color: $color_qcm_pre;  
}

.div_qcm_post { 
  display: inline-block;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: bold;
  background-color: $color_qcm_post;  
}

.cell_item
{
  border-radius:4px;
  padding:5px 10px;
  border:1px solid #eee;
  border-radius:10px;
  display: inline-block;
}

.box_shadow_principal
{ box-shadow: 0 0 4px $color_2 !important; }

.cell_item.box_shadow_principal{ border:0px; }

.button_small_size
{
  font-size:15px;
}


/*
PROGRESS COLORS
*/
.progress_color_0 { background-color: #D6EAF8;
  .progress-bar { background-color: #85C1E9 }
}
.progress_color_1 { background-color: #D1F2EB;
  .progress-bar { background-color: #73C6B6 }
}
.progress_color_2 { background-color: #FDEBD0;
  .progress-bar { background-color: #F8C471 }
}
.progress_color_3 { background-color: #E8DAEF;
  .progress-bar { background-color: #BB8FCE }
}
.progress_color_4 { background-color: #FCF3CF;
  .progress-bar { background-color: #F7DC6F }
}
.progress_color_5 { background-color: #FADBD8;
  .progress-bar { background-color: #F1948A }
}
.progress_color_6 { background-color: #E5E8E8;
  .progress-bar { background-color: #99A3A4 }
}
.progress_color_7 { background-color: #F2D7D5;
  .progress-bar { background-color: #D98880 }
}
.progress_color_8 { background-color: #D5D8DC;
  .progress-bar { background-color: #566573 }
}
.progress_color_9 { background-color: #D5F5E3;
  .progress-bar { background-color: #82E0AA }
}
